
import { Component, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import FacilityService from "@/services/facilities";
import { deleteNullProp } from "@/utils/Util";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import Editor from "@tinymce/tinymce-vue";
import { CreateAnnouncement } from "@/@types/management";
import { deleteFile, getS3Url, uploadToS3 } from "@/plugins/s3";

@Component({
  name: "HospitalAnnouncementSettingEdit",
  components: {
    Header,
    Table,
    ManagementFooter,
    Button,
    FormSet,
    Editor,
  },
})
export default class HospitalAnnouncementSettingEdit extends CheckAdminLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }

  private get me() {
    return AuthModule.me;
  }

  private announcementId = this.$route.params.id;
  private showLabel = false;
  private loading = false;
  private announcementList = [];
  private file: any = null;
  private isTinyEmpty = false;
  private tinyMceApiKey = process.env.VUE_APP_TINYMCE_API_KEY;
  private currentS3Key?: string = "";
  private replaceFile() {
    (this.$refs.fileInput as any).$el.querySelector("input").click();
  }

  private filePickerCallback(callback: any, value: any, meta: any) {
    if (meta.filetype === "image") {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.onchange = function () {
        if (!input.files) {
          return;
        }
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          callback(e.target?.result, {
            alt: file.name,
          });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    }
  }
  @Watch("form.body")
  private onBodyChange() {
    if (this.form.body_type === "TEXT") {
      this.isTinyEmpty = this.form.body === "";
    }
  }
  @Watch("file")
  private onFileChange() {
    this.currentS3Key = this.form.pdf_s3_url?.split(`${process.env.VUE_APP_S3_BUCKET_NAME}/`).pop();
    this.form.pdf_s3_url = "";
  }
  private async uploadFile() {
    if (!this.file) {
      return;
    }
    if (this.file.type !== "application/pdf") {
      this.showModal("fileTypeError");
      return;
    }
    this.loading = true;
    try {
      const res: any = await uploadToS3(this.file);
      this.form.pdf_s3_url = `s3://${process.env.VUE_APP_S3_BUCKET_NAME}/${res.Key}`;
      this.loading = false;
      return true;
    } catch (error: any) {
      this.showModal("saveError");
      this.loading = false;

      throw new Error(error);
    }
  }
  private form: CreateAnnouncement = {
    title: "",
    publish_start_at: "",
    publish_end_at: "",
    pdf_s3_url: "",
    body: "",
    body_type: "TEXT",
  };

  private get dateTimeCheck() {
    const start = moment(this.form.publish_start_at);
    const end = moment(this.form.publish_end_at);
    return end.isAfter(start);
  }

  private back() {
    this.$router.push({ name: "HospitalAnnouncementSetting" });
  }

  private async mounted() {
    await this.getAnnouncement();
  }

  private isSetPublishEndAt = false;

  private async getAnnouncement() {
    try {
      this.loading = true;
      const res = await FacilityService.getAnnouncements({ announcement_id: this.announcementId, has_body: true });
      this.form = res.data.data[0];
      this.isSetPublishEndAt = !!this.form.publish_end_at;
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async linkToAnnouncementDetail(link: string) {
    // pdfの場合は別タブで開く
    if (link === "") return;
    const key = link.split(`${process.env.VUE_APP_S3_BUCKET_NAME}/`).pop();
    if (!key) return;
    const url = (await getS3Url(key)) as string;
    window.open(process.env.VUE_APP_INFO_URL + "/" + key, "_blank");
    window.open(url, "_blank");
    return;
  }

  private async deleteAnnouncement() {
    try {
      this.loading = true;
      await FacilityService.deleteAnnouncementById(this.announcementId);
      if (this.form.body_type === "PDF" && this.form.pdf_s3_url) {
        const key = this.form.pdf_s3_url.split(`${process.env.VUE_APP_S3_BUCKET_NAME}/`).pop();
        await deleteFile(key!);
      }
      this.closeModal("delete");
      this.loading = false;
      this.$router.push({ name: "HospitalAnnouncementSetting" });
    } catch (error: any) {
      this.loading = false;
      this.showModal("deleteError");
      throw new Error(error);
    }
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async submit() {
    if (this.form.body_type === "TEXT") {
      this.isTinyEmpty = this.form.body === "";
    }
    if (!(await (this.$refs.observer as any).validate())) {
      return;
    }
    if (this.form.body_type === "TEXT" && this.isTinyEmpty) {
      return;
    }
    try {
      if (this.file) {
        const success = await this.uploadFile();
        if (this.currentS3Key) {
          await deleteFile(this.currentS3Key);
        }
        if (!success) {
          this.loading = false;
          return;
        }
      }
      this.loading = true;
      this.form.publish_start_at = moment(this.form.publish_start_at)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format();
      if (this.form.publish_end_at) {
        this.form.publish_end_at = moment(this.form.publish_end_at)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format();
        if (!this.dateTimeCheck) {
          this.loading = false;

          this.showLabel = true;
          setTimeout(() => {
            this.showLabel = false;
          }, 5000);
          return;
        }
      } else {
        delete this.form.publish_end_at;
      }
      const payload = deleteNullProp(this.form);
      if (this.form.body_type === "TEXT" && payload.pdf_s3_url) {
        payload.pdf_s3_url = null;
      }
      if (this.isSetPublishEndAt && !payload.publish_end_at) {
        payload.publish_end_at = null;
      }
      await FacilityService.updateAnnouncementById(this.$route.params.id, payload);
      this.$router.push({ name: "HospitalAnnouncementSetting" });
      this.loading = false;
    } catch (error: any) {
      if (!error.response) {
        this.showModal("fileSizeError");
      } else {
        this.showModal("saveError");
      }
      this.loading = false;
      throw new Error(error);
    }
  }
}
