import AWS from "aws-sdk";
import Cognito from "./cognito";
import type { CognitoUserSession } from "amazon-cognito-identity-js";

// Cognitoからクレデンシャルを取得する関数
const getCredentials = async (): Promise<AWS.Credentials> => {
  const currentUser = Cognito.userPool.getCurrentUser();
  console.log("currentUser", currentUser);
  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  return new Promise((resolve, reject) => {
    currentUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (error) {
        reject(error);
        return;
      }
      if (!session) {
        reject(new Error("No session"));
        return;
      }

      const idToken = session.getIdToken().getJwtToken();

      // Identity Poolの設定
      AWS.config.region = process.env.VUE_APP_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.VUE_APP_COGNITO_IDPOOL_ID || "",
        Logins: {
          [`cognito-idp.${process.env.VUE_APP_REGION}.amazonaws.com/${process.env.VUE_APP_COGNITO_USERPOOL_ID}`]:
            idToken,
        },
      });

      // クレデンシャルの取得
      (AWS.config.credentials as AWS.CognitoIdentityCredentials).get((err) => {
        if (err) {
          console.error(err);
          reject(err);
          return;
        }
        resolve(AWS.config.credentials as AWS.Credentials);
      });
    });
  });
};

// S3クライアントを作成する関数
const createS3Client = async (): Promise<AWS.S3> => {
  const credentials = await getCredentials();
  return new AWS.S3({
    credentials,
    region: process.env.VUE_APP_REGION,
    params: { Bucket: process.env.VUE_APP_S3_BUCKET_NAME },
  });
};

// S3へのアップロード
export const uploadToS3 = async (file: File): Promise<AWS.S3.ManagedUpload.SendData> => {
  const s3Client = await createS3Client();
  const timestamp = new Date().getTime();
  const filename = `file_${timestamp}`;

  const params: AWS.S3.PutObjectRequest = {
    Bucket: process.env.VUE_APP_S3_BUCKET_NAME || "",
    Key: filename,
    ContentType: file.type,
    Body: file,
  };

  return new Promise((resolve, reject) => {
    s3Client.upload(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

// S3からの署名付きURL取得
export const getS3Url = async (key: string): Promise<string> => {
  const s3Client = await createS3Client();
  const params = {
    Bucket: process.env.VUE_APP_S3_BUCKET_NAME || "",
    Key: key,
    Expires: 60 * 60, // 有効期限（秒）
  };

  return new Promise((resolve, reject) => {
    try {
      const url = s3Client.getSignedUrl("getObject", params);
      resolve(url);
    } catch (err) {
      reject(err);
    }
  });
};

// S3からのファイル削除
export const deleteFile = async (key: string): Promise<AWS.S3.DeleteObjectOutput> => {
  const s3Client = await createS3Client();
  const params: AWS.S3.DeleteObjectRequest = {
    Bucket: process.env.VUE_APP_S3_BUCKET_NAME || "",
    Key: key,
  };

  return new Promise((resolve, reject) => {
    s3Client.deleteObject(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};
